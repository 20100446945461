@import '@styles/variables.scss';

.navbar {
  border-bottom: 1px solid $color-border;
  box-shadow: none !important;
  padding: 16px 80px !important;
  }

  .partner-logo {
    height: 48px;
    width: auto;
  }

  .has-partner-with {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.42px;
    color: #8C95A6;
    margin: 0 1.5em 0 1.5em;
  }
  
  #navbar-brand-container {
    display: flex;
    align-items: center;
  }
  
  #navbar-brand-text {
    @include lesser-bold;
    font-size: 1.35em;
    color: $color-logo-primary;
    padding-top: 0.3em;
  }
  
  #nav-list {
    justify-content: flex-end;
  }
  
  .main-nav-link:focus {
    @include manual-outline;
  }
  
  .main-nav-link {
    @include lesser-bold;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    color: $color-actionable-navigation-item;
    cursor: pointer;
    border: none;
    background-color: transparent;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.08px;
  }
