@import '@styles/variables.scss';
.application-state {
    display: flex;
    flex-direction: column;
    margin-left: 50px !important;

    .state-text-container {
        display: inline-block;
        --bs-gutter-x: 0;
        .header {
            font-size: 23px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0.14px;
            color: $color-content;

            margin-bottom: 15px;
        }

        .paragraph {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1925px;
            margin-bottom: 15px;
            color: $color-content-weak;
        }
    }
}
