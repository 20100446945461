@import '@styles/variables.scss';

#confirm-sign-out {
    .modal-content {
        width: 656px !important;
    }

    .modal-header {
        padding: 0 1rem !important;
    }

    .grey-text {
        color: $color-content-weak;
    }

    .btn-cancel {
        color: $color-actionable-navigation-item;
        border: 2px solid $color-border;
        border-radius: $border-radius-default;
        background: $color-background-primary;
        margin-right: 1rem;
        &:hover {
            background-color: #e6e6e6;
        }
    }

    .modal-body-text {
        max-width: unset;

        .modal-small {
            font-size: 14px;
        }
    }

}