@import '@styles/variables.scss';
// Pills
.pill {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;
  border: 1px solid $color-semantic-positive;
  color: $color-semantic-positive !important;
  opacity: 1 !important;
  font-weight: 400 !important;
  padding: 0 10px;
  height: 30px;
  margin: 5px 5px 0 0;

  .content {
    padding: 5px 0;
  }

  button {
    padding: 0px 0px 0 5px !important;
    color: $color-semantic-positive !important;
    opacity: 1 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    text-shadow: none !important;
  }
}

.pill:hover {
  background-color: #3f713f0d;
  opacity: .5;
}
