@import '@styles/variables.scss';

label {
  display: inline-block;
  max-width: 100%;
  margin: auto;
  margin-bottom: 4px;
  font-size: 14px;
  color: $color-content;
  font-weight: 400;
}

.checkbox {
  input[type="checkbox"] {
    opacity: 0;
    width: 0;

    + label::after {
      content: none;
    }

    &:checked + label::after {
      content: "";
    }

    &:focus + label::before {
      @include manual-outline;
    }
  }

  label {
    position: relative;
    display: inline-block;
    padding-left: 22px;

    &:hover {
      cursor: pointer;
    }

    &::before, &::after {
      position: absolute;
      content: "";
      display: inline-block;
    }

    &::before {
      height: 16px;
      width: 16px;
      border: 1px solid;
      left: 0px;
      top: 3px;
    }

    &::after {
      height: 5px;
      width: 9px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      left: 4px;
      top: 7px;
    }
  }
}

.form-check {
  display: flex !important;
  margin: $spacing-xl 0 !important;

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1925px;
    color: $color-content-weak;
    margin-right: 16px;
    margin-left: 0;
  }

  .form-check-input {
    margin-top: 3.5px;
    width: 24px;
    height: 24px;
  }

  .tooltip-icon-container {
    margin-left: 5px;
  }
}

.fake-input {
  width: auto !important;
  height: 50px !important;
  padding: 0 !important;
  display: block !important;

  &.disabled {
    background-color: #ededf0;
  }
}

input.form-control, select.form-control {
  height: 50px;
  border: 1px solid $color-border-strong;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 4px;
  width: 100%;


}

.form-check-label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 0;
  letter-spacing: 0.1925px;
}

.react-datepicker__input-container{
  position: relative;  

  &:after {
    position: absolute;
    left: calc(100% - 25px);
    top: 10px;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'%3E%3Cpath d='M19.5 19H5.5V8H19.5M16.5 1V3H8.5V1H6.5V3H5.5C4.39 3 3.5 3.89 3.5 5V19C3.5 19.5304 3.71071 20.0391 4.08579 20.4142C4.46086 20.7893 4.96957 21 5.5 21H19.5C20.0304 21 20.5391 20.7893 20.9142 20.4142C21.2893 20.0391 21.5 19.5304 21.5 19V5C21.5 3.89 20.6 3 19.5 3H18.5V1M17.5 12H12.5V17H17.5V12Z' fill='%233A3B41'/%3E%3C/svg%3E");
  }

  input {
    width: 100%;
    border: 2px transparent solid;
    border-radius: 0.25rem;
    padding: 5px;
    height: 40px;
  }
}

.has-error {
  label { 
    color: red;
  }
}

.form-check-input[type=radio] {
  margin-top: 2px;
  margin-right: 16px;
  width: 24px;
  height: 24px;
  &:checked[type=radio] {
    background-color: #fff;
    border: 2px solid $color-semantic-info-strong;
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%231f63d2'/%3e%3c/svg%3e");
    padding: 10px
  }

  &:checked ~ .checkmark:after {
    color: green;
  }

  -webkit-appearance: none;
  appearance: none;
}

.form-group {
  position: relative;
}

.select-container::after {
  content: url("data:image/svg+xml; utf8, %3Csvg%20width%3D%2212%22%20height%3D%228%22%20viewBox%3D%220%200%2012%208%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M9.87998%201.29006L5.99998%205.17006L2.11998%201.29006C1.72998%200.900059%201.09998%200.900059%200.70998%201.29006C0.31998%201.68006%200.31998%202.31006%200.70998%202.70006L5.29998%207.29006C5.68998%207.68006%206.31998%207.68006%206.70998%207.29006L11.3%202.70006C11.69%202.31006%2011.69%201.68006%2011.3%201.29006C10.91%200.910059%2010.27%200.900059%209.87998%201.29006Z%22%20fill%3D%22%23586272%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  font-size: 1rem;
  top: 28px;
  right: 23px;
  position: absolute;
}

.has-label.select-container::after {
  top: 44px;
}
