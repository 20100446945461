.tooltip-container {
    max-width: 219px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.3px;
    color: #586272 !important;

    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    border: unset !important;
    border-radius: 6px !important;
    padding: 10px !important;
  }

  .tooltip-icon-container {
      display: inline-block;
  }