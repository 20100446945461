@import '@styles/variables.scss';
// Chips
  .chip {
    border-radius: $border-radius-default;
    width: 184px;
    //margin: 0 $spacing-m $spacing-m 0;
    filter: opacity(25%);
    border: 1px solid $color-grey-mid;
    box-shadow: 0px 6px 8px #EFF9F8;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;

    &:last-of-type {
      margin-right: 0;
    }
    
    &.selected {
      font-weight: 400 !important;
      border: 2px solid $color-semantic-info-strong !important;
      filter: opacity(100%);
    }
    
    display: inline-flex;
    padding: $spacing-m $spacing-s $spacing-m $spacing-m;
    height: 56px;

    svg {
      width: 24px;
      height: 24px;
      align-self: center;
    }

    span {
      display: flex;
      align-items: center;
      color: $color-body-header;
      margin-left: 10px;
      font-size: 16px;
      line-height: 24px;
      max-width: 130px;
    }

    &.disabled {
      // filter: opacity(50%) !important;
      span {
        color: #B2B7BF;
      }
      cursor: not-allowed;
      background-color: #EDEDED;

      svg {
        path, g path, rect {
          fill: #B2B7BF !important;
          stroke: #B2B7BF !important;
        }
      }
    }

    &.disabled.no-stroke {
      path, g path {
        stroke: none !important;
      }
    }

    &.initial {
      border: 1px solid $color-grey-mid;
      filter: opacity(100%);
      box-shadow: none;
    }

    .chip-checkmark {
      position: absolute;
      top: -7px;
      left: 150px;
      display: none;
    }

  }