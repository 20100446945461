@import '@styles/variables.scss';

// processing
div.processing {
    width: 100%;
    height: 100%;
    cursor: wait;
  
    &#users-processing {
      justify-content: center;
    }
  
    &#card-processing {
      position: absolute;
      z-index: 9;
      overflow-y: hidden;
      left: 0;
      > div {
        @include vertical-center;
      }
    }
  
    &#page-processing {
      position: fixed;
      z-index: 101;
      > div {
        @include vertical-center;
      }
    }
  }
  
  .loading-dots {
    display: block;
    font-size: 38px;
    font-family: monospace;
    color: $color-logo-primary;
    opacity: 0.5;
  }
  
  .loading-dots span:nth-of-type(1) {
    -webkit-animation-duration: 1s;
    -webkit-animation-name: uplift_loading_loop;
    -webkit-animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-duration: 1s;
    -moz-animation-name: uplift_loading_loop;
    -moz-animation-delay: 0s;
    -moz-animation-iteration-count: infinite;
    animation-duration: 1s;
    animation-name: uplift_loading_loop;
    animation-delay: 0s;
    animation-iteration-count: infinite
  }
  
  .loading-dots span:nth-of-type(2) {
    margin-left: 5px;
    -webkit-animation-duration: 1s;
    -webkit-animation-name: uplift_loading_loop;
    -webkit-animation-delay: 0.1s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-duration: 1s;
    -moz-animation-name: uplift_loading_loop;
    -moz-animation-delay: 0.1s;
    -moz-animation-iteration-count: infinite;
    animation-duration: 1s;
    animation-name: uplift_loading_loop;
    animation-delay: 0.1s;
    animation-iteration-count: infinite
  }
  
  .loading-dots span:nth-of-type(3) {
    margin-left: 5px;
    -webkit-animation-duration: 1s;
    -webkit-animation-name: uplift_loading_loop;
    -webkit-animation-delay: 0.2s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-duration: 1s;
    -moz-animation-name: uplift_loading_loop;
    -moz-animation-delay: 0.2s;
    -moz-animation-iteration-count: infinite;
    animation-duration: 1s;
    animation-name: uplift_loading_loop;
    animation-delay: 0.2s;
    animation-iteration-count: infinite
  }
  
  @keyframes uplift_loading_loop {
    0% {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
      opacity: 0.1
    }
    50% {
      filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
      opacity: 1
    }
    100% {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=10);
      opacity: 0.1
    }
  }

  #uplift-transition-panel .table-cell {
    vertical-align: middle;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1;
    font-size: 20px;
    padding-top: 1em;
    text-transform: uppercase
  }


  .text-mayus {
    text-transform: uppercase;
  }