@import '@styles/variables.scss';

.ordered-list {

    .list-item::before {
        display: block;
        font-weight: 300;
        content: attr(data-index);
        font-size: 15px;
        width: 25px;
        height: 25px;
        border-radius: 100%;
        text-align: center;
        color: white;
        background-color: $color-semantic-info-strong;
        box-sizing: border-box;
        line-height: 24px;
    }

    .list-item:not(:last-child)::after {
        content: '';
        margin: 0 12px;
        border-left: 1px dashed $color-semantic-info-strong;
    }

    .content {
        padding-left: 16px;
        grid-row: span 2;
    }

    .list-item {
        display: grid;
        grid-template-columns: 25px 1fr;
        grid-template-rows: min-content 1fr;
    }


}