@import '@styles/variables.scss';

.wait_for_client {
    .ball-title {
        display: flex;
        opacity: 1;
        transition: opacity 500ms linear;

        .icon {
            background-color: $color-semantic-info-strong;
            padding: 10px;
            width: 25px;
            height: 25px;
            border-radius: 100%;
        }

        span {
            font-style: normal;
            font-weight: 500;
            font-size: 23px;
            line-height: 32px;
            letter-spacing: 0.14px;
            margin: auto 0 auto 23px;

            color: $color-content;
        }

        &.active {
            opacity: 1;
        }

        &.disabled {
            span {
                color: $color-content-disabled;
            }
        }

    }

    .middle-box {
        display: flex;
        margin-top: -30px;
        min-height: 50px;
        transition: height 500ms ease-in-out;

        .dashed-border.fr {
            margin-top: -28px;
        }

        .dashed-border {
            border-left: 1px dashed $color-semantic-info-strong;
            margin-left: 11px;
        }

        .middle-box-content {
            padding: 40px 20px 20px 35px;
        }

        &.borderless {
            border-left: unset;
            flex-direction: column;
            margin-top: $spacing-s;
            margin-left: $spacing-2xl;
        }

        &.disabled {
            opacity: 0.5;
        }

        .paragraph {
            padding-bottom: 20px;
            display: block;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.1925px;
            color: $color-content-weak;
        }

        .resend-option {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 17px 0;

            .form-check{
                min-width: 90px;
                margin: 0 !important;
            }
            .client-data {
                flex: 3;
                color: $color-content-weak;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
            }
        }
    }

    .btn-resend, .btn-edit {
        color: black !important;
        background-color: unset !important;
        // margin-top: 32px;
        margin: 32px 0 40px 0;
        font-size: 14px;
        line-height: 24px;

        &:disabled {
            cursor: wait;
        }
    }

    .btn-edit {
        color: $color-actionable-primary !important;
        padding: 0 24px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }

    .btn-resend {
        border: 2px solid $color-border !important;
        border-radius: 8px;
        padding: 8px 32px;
        background-color: white !important;
        color: $color-actionable-navigation-item;
        font-weight: 500;
    }

    .btn:disabled, input:disabled {
        cursor: not-allowed !important;
    }
}