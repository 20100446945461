$color-logo-primary: #0E680E;
$color-primary-dark: #178077;
$color-mid-blue: #00A6FF;
$color-logo-secondary: #2462BC;
$color-secondary-dark: #EE6F04;
$color-secondary-mid: #F09C0C;
$color-secondary-light: #F8FFFE;
$color-disabled-blue: #B9DFFF;
$color-success: #28A745;
$color-danger: #DC0606;
$color-label: #586272;
$color-grey-dark: #657183;
$color-grey-mid: #D1D3D7;
$color-grey-light: #F5F5F5;
$color-display-grey: #585858;
$color-body-header: #252930;
$color-body-subheader: #383D47;
$color-body-text: #586272;
$color-link: #156CED;
$color-disable-button: #99bd9b;
$color-hover-button: #3f713f;

$color-actionable-navigation-item: #3A3B41;
$color-actionable-primary: #0E680E;
$color-background-primary: #FFF;
$color-background-page: #F6F6F9;
$color-background-strong: #EAECF0;
$color-semantic-info-strong: #1F63D2;
$color-semantic-error: #B52518;
$color-semantic-positive: #4A9D2D;
$color-button-primary: #0E680E;
$color-actionable-on-button-primary: #FFF;
$color-border: #CFD1D8;
$color-border-strong: #8E919C;
$color-content: #3A3B41;
$color-content-disabled: #8E919C;
$color-content-weak: #686C78;
$color-info-strong-background: #E3F1F7;

$spacing-xs: 4px;
$spacing-s: 8px;
$spacing-m: 16px;
$spacing-l: 24px;
$spacing-xl: 32px;
$spacing-2xl: 48px;
$spacing-3xl: 64px;

$border-radius-default: 8px;


$theme-colors: (
  "primary": $color-logo-primary,
  "danger": $color-danger,
  "success": $color-success,
  "secondary": $color-logo-secondary,
  "warning": $color-secondary-mid,
  "light": $color-grey-light,
  "dark": $color-grey-dark,
  "link": $color-link
);

@mixin divider-color {
  background: $color-logo-secondary;
}

@mixin divider-grey {
  background: linear-gradient(to right, $color-grey-dark 0%, $color-grey-mid 100%);
}

@mixin manual-outline {
  outline: 2px solid #7AACFE !important; /* for non-webkit browsers */
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px;
}

@mixin header-divider {
  margin: 1em 0 1em 0;
  border-radius: 1px;
  border: none;
  height: 2px;
  opacity: 0.5;
}

@mixin card-box-shadow {
  -webkit-box-shadow: 0px 0px 7px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 7px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 7px 0 rgba(0, 0, 0, 0.2);
}

@mixin color-light-blue {
  color: $color-logo-primary;
}

@mixin lesser-bold {
  font-weight: 500;
}

@mixin vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}