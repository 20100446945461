@import '@styles/variables.scss';

.pasword-requirements-container {
    background-color: $color-background-page;
    width: 100%;
    height: auto;
    color: black;
    padding: $spacing-m;
    border-radius: 5px;

    .password-requirement {
        display: flex;
        margin-bottom: $spacing-s;
        .veredict {
            margin: auto;
            .validation-icon {
                width: 20px;
                height: 20px;
                margin-right: $spacing-xs;

                &.show {
                    display: inline-block;
                }

                &.hide {
                    opacity: 0;
                }
            }

        }

        .requirement-text {
            flex: 8;
            margin: auto;
            font-size: 12px;
            max-width: 100%;
            color: $color-content;
            font-weight: 400;
        }
    }
}
