.phone-preview {
    .title {
        text-align: center;
        margin-bottom: 12px;
    }

    svg {
        text-align: center;
        display: inline-block;
        width: 100%;
    }
}