@import '@styles/variables.scss';

.modal {
    .modal-content {
        width: 631px !important;
        border-radius: 8px;
        margin: auto;
        padding: $spacing-3xl $spacing-l $spacing-l $spacing-l;
    }

    .modal-dialog-centered {
        max-width: unset;
    }

    .modal-header {
        border-bottom: none !important;
        padding: 0 !important;
    }

    .close {
        position: absolute;
        top: $spacing-l;
        right: $spacing-l;
        box-shadow: none;
        border: none;
        background-color: transparent;
        svg {
            width: 14.142px !important;
            height: 14.142px !important;
            flex-shrink: 0;
        }
    }

    .modal-body {
        margin-top: 0 !important;

        p {
            font-size: 16px;
        }
    }

    .modal-footer {
        margin-top: $spacing-2xl;
        border-top: none !important;
        padding: 0px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
            height: 56px;
            margin: 4px;
            text-align: center;
            text-transform: capitalize;
            width: auto !important;
            flex: 1;

            &:hover{
                background: $color-logo-primary;
            }
        }
    }

    &.show {
        display: block;
    }

    .modal-title {
        font-style: normal;
        font-size: 29px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        color: #252930;
        margin-bottom: 20px;
    }

    .modal-body {
        padding: 0px;
        padding-top: $spacing-m;
    }

    .modal-body-text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.2475px;
        color: #383D47;
        margin-bottom: 0px;
    }


    .center-buton {
        background: $color-logo-primary;
        border-radius: 8px;
        width: 281px;
        margin: auto;
        padding: 11px;
        margin-bottom: 20px;
    }

}

body.modal-open {
    overflow: hidden;

    .modal-overlay {
        display: block;
    }
}

.modal-overlay {
    background-color: #252930;
    opacity: 0.5;
    top: 0;
    left: 0;
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
}