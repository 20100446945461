@import './variables.scss';
@import "node_modules/bootstrap/scss/bootstrap";
@import 'react-popper-tooltip/dist/styles.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.divider-color {
  @include divider-color;
}

.divider-grey {
  @include divider-grey;
}

.manual-outline {
  @include manual-outline;
}

.header-divider {
  @include header-divider;
}

.card-box-shadow {
  @include card-box-shadow;
}

.color-light-blue {
  @include color-light-blue;
}

.lesser-bold {
  @include lesser-bold;
}

.vertical-center {
  @include vertical-center;
}

// styles fonts
@font-face {
  font-family: Graphik;
  font-display: swap;
  src: url("https://www.upgrade.com/assets/fonts/Graphik-Medium-Web.eot");
  src:
    url("https://www.upgrade.com/assets/fonts/Graphik-Medium-Web.eot?#iefix") format("embedded-opentype"),
    url("https://www.upgrade.com/assets/fonts/Graphik-Medium-Web.woff2") format("woff2"),
    url("https://www.upgrade.com/assets/fonts/Graphik-Medium-Web.woff") format("woff");

  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Graphik;
  font-display: swap;
  src: url("https://www.upgrade.com/assets/fonts/Graphik-Medium-Web.eot");
  src:
    url("https://www.upgrade.com/assets/fonts/Graphik-Medium-Web.eot?#iefix") format("embedded-opentype"),
    url("https://www.upgrade.com/assets/fonts/Graphik-Medium-Web.woff2") format("woff2"),
    url("https://www.upgrade.com/assets/fonts/Graphik-Medium-Web.woff") format("woff");

  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Graphik;
  font-display: fallback;
  src: url("https://www.upgrade.com/assets/fonts/Graphik-Regular-Web.eot");
  src:
    url("https://www.upgrade.com/assets/fonts/Graphik-Regular-Web.eot?#iefix") format("embedded-opentype"),
    url("https://www.upgrade.com/assets/fonts/Graphik-Regular-Web.woff2") format("woff2"),
    url("https://www.upgrade.com/assets/fonts/Graphik-Regular-Web.woff") format("woff");

  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Graphik;
  font-display: swap;
  src: url("https://www.upgrade.com/assets/fonts/Graphik-Light-Web.eot");
  src:
    url("https://www.upgrade.com/assets/fonts/Graphik-Light-Web.eot?#iefix") format("embedded-opentype"),
    url("https://www.upgrade.com/assets/fonts/Graphik-Light-Web.woff2") format("woff2"),
    url("https://www.upgrade.com/assets/fonts/Graphik-Light-Web.woff") format("woff");

  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

.text-transform-none {
  text-transform: none !important;
}

.subheader {
  color: #383D47;
  font-size: 1.125rem;
}

* {
  font-family: Graphik, Inter, Roboto;
}

.btn {
  color: #FFFFFF;
  border: none;
}

button {
  .spinner-border{
    margin: auto;
    margin-left: 8px;
    margin-right: 8px;
  }
}

body {
  font-family: Graphik, Inter, Roboto;
  font-size: 12pt;
  color: $color-body-text;
  width: 100%;
  overflow-x: hidden;
}

a, span.link-button, button.btn-link {
  cursor: pointer;
  color: $color-logo-primary;
  border: none;

  text-decoration: none;
  background-color: transparent;

  &:hover, &:focus {
    text-decoration: underline;
  }
}

a, a:active, a:hover, a:visited {
  color: $color-link !important;
}

button.btn-link:hover {
  background-color: transparent !important;
}


button.btn-link {
  color: $color-logo-primary !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-sm-right {
  text-align: right !important;
}

#app {
  background-color: #ffffff;
}

#app:not(.axe) {
  .btn-primary:focus,
  span.link-button:focus,
  span.close-circle:focus,
  .checkbox:focus + label::before,
  th.sortable:focus,
  tr.manage-row,
  .toggle-container .toggle:focus + .toggle-btn,
  .nav-link:focus {
    outline: none !important;
  }
}

// Genesys Chat
#genesys-chat {
  box-shadow: none;
  background-color: $color-logo-primary !important;
  color: #FFFFFF !important;
  fill: #FFFFFF !important;
  padding: 0.92857rem 1.57143rem;
  border-radius: 999rem;
  bottom: 0;
  border: none !important;
  font-weight: 700;
  box-sizing: border-box;
  margin: 0;
  min-width: 100%;
  table-layout: auto;
  white-space: nowrap !important;
  text-align: left !important;
  cursor: pointer !important;
  display: inline-block !important;
  max-width: 100%;
}

#modal {
  position: absolute;
  background-color: white;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 100%;
  max-height: 85%;
  overflow-x: hidden;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  box-shadow: 0px 0px 7px 0 rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -50%);
  color: #4A4A4A;
  font-size: 1rem;

  ol {
    list-style: none;
    counter-reset: modal-counter;
    padding-left: 6em;

    li {
      counter-increment: modal-counter;
      position: relative;
      margin-bottom: 2.5em;
      padding-top: 0.2em;

      &::before {
        font-weight: 300;
        content: counter(modal-counter) " ";
        font-size: 1.2rem;
        position: absolute;
        top: 0;
        border-radius: 50%;
        text-align: center;
        border: 1px solid $color-primary-dark;
        color: $color-primary-dark;
        background-color: white;
      }
    }
  }

  .disclaimer {
    font-size: .85rem;
    color: $color-grey-dark;

    .header-divider {
      @include divider-grey;
    }
  }
}

.step-card,
.dashboard-card,
.page-background-card {
  margin: 35px auto;
  max-width: 1370px;

  & > .card-body {
    padding: 0;
  }
}



#modal,
.page-background-card,
.dashboard-card,
.step-card-active {
  .header-divider {
    @include divider-color;
  }
}

.filter-card,
#dashboard-app-card,
#edit-tenant-card,
#edit-user-card,
.step-card-inactive {
  .header-divider {
    @include divider-grey;
  }
}

.overflow-x-auto {
  overflow-x: auto;
}


.color-grey-dark {
  color: $color-grey-dark;
}

.globe-symbol {
  font-size: 0.9em;
}

thead th {
  position: relative;

  &.sortable:hover,
  &.sortable:focus {
    color: $color-logo-secondary;
    cursor: pointer;
  }

  &.sortable:focus {
    @include manual-outline;
  }

  &.up-arrow, &.down-arrow {
    color: $color-logo-secondary;
    cursor: pointer;
  }

  &.up-arrow > span::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    right: 0.25em;
    top: 29px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $color-logo-secondary;
  }

  &.down-arrow > span::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    right: 0.25em;
    top: 29px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $color-logo-secondary;
  }
}

.remove-new-user,
.add-role-btn,
.header-btn,
.partner-action-btn,
.reset-filter-btn,
.download-csv-btn,
#sign-out-link,
#setup-status-action,
.lang-link {
  cursor: pointer;
  color: $color-link;
  border: none;
  background-color: transparent;

  &.inactive, &.enabled {
    &:focus, &:hover {
      text-decoration: underline;
    }
  }

  &:hover, &:disabled, &.active, &.disabled {
    color: #003366;
  }

  &.active, &.disabled {
    cursor: not-allowed;
  }
}

#goto-um-add-users-button {
  width: auto;
}

.partner-action-btn,
#setup-status-action {
  &.processing {
    cursor: wait;
  }
}

strong {
  @include lesser-bold;
}

.link-button {
  font-size: 1.125rem;
  cursor: pointer;

  &:focus {
    @include manual-outline;
  }
}

#edit-button {
  float: right;
  cursor: pointer;
  padding: 4px 12px;
}

#edit-button:disabled {
  cursor: progress;
}

#send-new-offer, #cancel-edit {
  margin-top: 10px;
}

.trip-nav-button {
  text-transform: none;
  cursor: pointer;
  color: $color-body-header;
  font-size: 1.125rem;
  font-weight: normal;
  margin: 0.5em 1em 0 1em;
  background-color: transparent;
  border-radius: 2em !important;
  border: 1px solid $color-logo-primary;
}

.trip-nav-button:hover, .trip-nav-active {
  color: white;
  background-color: $color-logo-primary;
}

.trip-inputs-container {
  margin-top: 1em;
}

.align-items-end > div {
  margin-bottom: 25px;
}

.red-error-box {
  background-color: white;
  color: red;
  font-size: 13px;
  margin-top: 1em;
  margin-bottom: 1em;

  span, p, li {
    font-weight: 400 !important;
    font-size: 13px;
    font-style: normal;
    line-height: 16px;
    color: $color-content-weak;
  }

  a {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-decoration-line: underline;
    color: $color-semantic-info-strong;
  }

  p:first-child {
    font-size:  14px;
    font-weight: 500 !important;
    color: black;
    line-height: 24px;
  }

  &.single-line {
    font-weight: 400;
    font-size: 14px;
    align-items: end;
  }
}

.green-message-box {
  text-align: left;
  line-height: 16px;
  margin-bottom: $spacing-l;

  .body {
    font-size: 13px;
    color: $color-content-weak;
    font-weight: 400 !important;
  }

  .title {
    color: black;
    font-size: 14px;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 24px;
    margin-bottom: $spacing-xs;
  }
}

.alert-message-box {
  color: rgb(110 110 110);
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 1em;
}

.message-box {
  white-space: pre-line;
}

.tiny-dark-text {
  font-size: 14px;
  color: #4A4A4A;
}

.blurry-backdrop {
  opacity: 0.7;
  filter: blur(7px);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .blurry-backdrop {
    opacity: 0.2;
  }
}

/*
 ------------------------------------------------------------------------------- buttons
*/
.btn-primary {
  background-color: $color-logo-primary;
  border: none;
  width: 100%;
  color: white;
  -webkit-appearance: none !important;

  &:active,
  &:hover {
    background-color: $color-hover-button;
    color: white !important;
  }

  &.btn-grey {
    background-color: $color-grey-dark;

    &:hover, &:active, &.active {
      opacity: 0.7;
    }
  }
}

.offset-bottom-sm {
  margin-bottom: 10px;
}

.offset-bottom {
  margin-bottom: 20px;
}


.offset-top-sm {
  margin-top: 10px;
}

.offset-bottom {
  margin-bottom: 20px;
}

svg.icon-exclamation {
  fill: #97abff4a;
}

.p {
  margin-bottom: 5px;
}

.font-size-1rem {
  font-size: 1rem !important;
}

// User Management
.filter-row {
  height: 0;
  position: relative;
  z-index: 3;
}

.filter-card {
  @include card-box-shadow;
  opacity: 0.95;

  h5.filter-title {
    @include lesser-bold;
  }

  .filter-checkbox-list {
    max-height: 15em;
    overflow-y: auto;
  }

  .close {
    font-size: 2.5rem;
    line-height: 0.5;
  }
}

#none-found {
  background-color: transparent;
  border-radius: 0.3em;

  h1 {
    @include lesser-bold;
  }
}

.btn-outline {
  background-color: transparent;
  background-image: none;
  text-transform: uppercase;

  &:disabled {
    cursor: wait;

    &.bulk-submit,
    &#export-users-button {
      cursor: not-allowed;
    }
  }

  &:hover, &:active, &.active {
    color: white;
  }

  &-blue {
    color: $color-logo-primary;
    border-color: $color-logo-primary;

    &:hover, &:active, &.active {
      background-color: $color-logo-primary;
    }
  }

  &-grey {
    color: $color-grey-dark;
    border-color: $color-grey-dark;

    &:hover, &:active, &.active {
      background-color: $color-grey-dark;
    }
  }

  &-success {
    color: $color-success;
    border-color: $color-success;

    &:hover, &:active, &.active {
      background-color: $color-success;
    }
  }

  &-danger {
    color: $color-danger;
    border-color: $color-danger;

    &:hover, &:active, &.active {
      background-color: $color-danger;
    }
  }

  &-secondary-dark {
    color: $color-secondary-dark;
    border-color: $color-secondary-dark;

    &:hover, &:active, &.active {
      background-color: $color-secondary-dark;
    }
  }
}

.btn-outline-grey {
  color: #657183;
  border-color: #657183;
}

#new-users-app-role-list {
  max-height: 10em;
  overflow: auto;
}

#new-users-app-role-selection {
  & .form-group {
    margin-bottom: 0;
  }

  .input-error-text {
    max-width: 24rem;
  }
}

#new-users-app-role-selection,
#edit-tenant-card,
#edit-user-card {
  .access-list-item {
    overflow-wrap: anywhere;

    .close {
      font-size: 2rem;
      line-height: 0.5;
    }

    .close,
    .close:not(:disabled):not(.disabled):hover,
    .close:not(:disabled):not(.disabled):focus {
      color: $color-danger;
    }
  }
}

#dashboard-app-card,
#edit-tenant-card {
  background-color: $color-grey-light;
}


#dashboard-app-card,
#edit-tenant-card,
#edit-user-card {
  border-radius: 0.6em;

  section + section {
    margin-top: 0.5em;
  }

  .edit-tenant-nav,
  .edit-user-nav {
    box-shadow: none;
  }

  .edit-tenant-md-title,
  .edit-user-md-title {
    margin-bottom: 0.5em;
  }

  .header-group {
    .header {
      overflow-wrap: anywhere;
    }

    .close {
      font-size: 3rem;
      line-height: 0.5;
    }
  }
}

.um-partner-row {
  .list-group-item-action {
    cursor: pointer;

    &:disabled {
      cursor: default;
    }
  }
}

.dashboard-app-section,
.edit-tenant-section,
.edit-user-section {
  background-color: white;
  border: none;

  .card-body {
    padding-top: 1em;
  }
}

.new-user-card {
  background-color: $color-grey-light;
  border: none;

  & + & {
    margin-top: 0.5em;
  }

  .new-user-close-container {
    margin-right: -0.8em;

    .close,
    .close:not(:disabled):not(.disabled):hover,
    .close:not(:disabled):not(.disabled):focus {
      color: $color-danger;
    }
  }

  .new-user-inputs {
    margin-top: -0.5em;
  }
}

#add-users-progress {
  .progress-bar {
    background-color: $color-logo-primary;
  }
}

#process-new-user-list {
  .badge {
    @include lesser-bold;
    font-size: 1rem;
    color: white;

    &.badge-blue {
      background-color: $color-logo-primary;
    }

    &.badge-error {
      background-color: $color-secondary-mid;
    }

    &.badge-fail {
      background-color: $color-danger;
    }
  }
}

.bg-color-secondary-light {
  background-color: $color-secondary-light;

  .link-button {
    color: $color-link;
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  cursor: wait;

  &:after {
    content: " ";
    display: block;
    width: 1.35em;
    height: 1.35em;
    margin: 1px;
    border-radius: 50%;
    border: 4px solid $color-logo-primary;
    border-color: $color-logo-primary transparent $color-logo-primary transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#uploaded-csv {
  .csv-close-container {
    margin-right: -0.8em;

    .close,
    .close:not(:disabled):not(.disabled):hover,
    .close:not(:disabled):not(.disabled):focus {
      color: $color-secondary-dark;
    }
  }
}

.bulk-error-msg {
  color: $color-danger;
  font-size: 14px;
}

.subvention-from-pricing-note {
  background-color: hsl(0, 72%, 94%);

  p {
    display: inline;
  }
}

.radio-container {
  label {
    border: 1px solid $color-grey-mid;
    border-radius: .25rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
    padding: .75rem 1rem;
    position: relative;
    width: 100%;

    &.selected {
      background-color: hsl(210, 85.7%, 97.3%);
      border-color: $color-logo-primary;

      &.disabled {
        background-color: $color-grey-light;
        border-color: $color-grey-mid;

        .radio-input {
          &:checked {
            & + .radio-label {
              border-color: $color-grey-dark;
            }
          }
        }

        .radio-label {
          &::after {
            background: $color-grey-dark;
          }
        }
      }
    }

    &.disabled {
      cursor: default;
    }

    .radio-label {
      -webkit-tap-highlight-color: transparent;
      border: .125rem solid $color-label;
      border-radius: 100%;
      display: block;
      float: left;
      height: 1.25rem;
      margin: .125rem .5rem 0 0;
      position: relative;
      width: 1.25rem;

      &::after {
        background: $color-logo-primary;
        border-radius: 100%;
        content: '';
        height: .625rem;
        left: .1875rem;
        opacity: .08;
        pointer-events: none;
        position: absolute;
        top: .1875rem;
        transform: scale(0);
        transition: all .2s ease;
        width: .625rem;
      }
    }

    &:hover {
      border-color: $color-logo-primary;
      .radio-label {
        &::after {
          transform: scale(3.6);
        }
      }

      &.disabled:not(.selected) {
        border-color: $color-grey-mid;
        .radio-label {
          &::after {
            transform: none;
            opacity: 0;
          }
        }
      }
    }
  }

  .radio-input {
    cursor: pointer;
    opacity: 0;
    position: fixed;

    &:checked {
      & + .radio-label {
        border-color: $color-logo-primary;

        &::after {
          opacity: 1;
          transform: scale(1);
          transition: all .2s cubic-bezier(.35, .9, .4, .9);
        }
      }

      & ~ .radio-text {
        @include lesser-bold;
      }

      &:focus + .radio-label,
      &:hover + .radio-label {
        &::after {
          transform: scale(1.2);
        }
      }

      &:disabled {
        &:focus + .radio-label,
        &:hover + .radio-label {
          &::after {
            transform: none;
          }
        }
      }
    }

    &:focus {
      &:not(:checked) + .radio-label {
        &::after {
          transform: scale(3.6);
        }
      }
    }

    &:disabled {
      &:focus {
        &:not(:checked) + .radio-label {
          &::after {
            transform: none;
          }
        }
      }

      &:hover {
        cursor: default;
      }
    }
  }
}

.center-content {
  text-align: center;
}

.bold {
  font-weight: 600 !important;
}

button.btn-empty {
  text-transform: uppercase;
}

.chip-container {
  gap: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex {
  display: flex;
}
