@import '@styles/variables.scss';

#general-error {
    .modal-content {
        width: 656px !important;
    }

    .modal-header {
        padding: 0 1rem !important;
    }

    .btn {
        color: white;
        text-transform: capitalize;
    }

    .modal-body-text {
        max-width: unset;
        .modal-small {
            font-size: 16px;
        }
    }

}