@import '@styles/variables.scss';

#confirm-leave {
    .modal-content {
        width: 632px !important;
    }

    .modal-header {
        padding: 0 1rem !important;
    }

    .btn-outline-primary {
        border-radius: $border-radius-default;
        border: 2px solid $color-border;
        background: $color-background-primary;
        color: $color-content;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }
}