@import '@styles/variables.scss';

.tracker {
    height: 72px;
    display: flex;
    margin-left: 20px !important;
    margin-right: 20px !important;

    .step {
        display: inline-block;
        text-align: center;
        margin-top: 10px;
        position: relative;
        border-top: 2px solid $color-border;

        .step-wrapper {
            width: 29px;
            height: 29px;
            display: inline-block;
            border-radius: 4px;
            margin-top: 0;
            border: 1px #B2B7BF solid;
            transition: width 50ms ease-in-out;
            transition: height 50ms ease-in-out;
        }

        .step-name {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            margin-top: 14px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.6px;
            color: $color-content;
            transition: color 200ms ease-in-out;
        }

        &.current {
            border-top: 2px solid  $color-semantic-info-strong;

            .step-name {
                font-weight: 500;
            }
        }

        &.done {
            border-top: 2px solid  $color-semantic-info-strong;

            .step-name {
                margin-top: 14px;
                font-weight: 500;
            }
        }
    }
}