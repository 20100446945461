@import '@styles/variables.scss';

.session-eneded {
    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 32px;
        letter-spacing: 0.14px;
        color: $color-content;
        display: block;
        margin-bottom: 20px;
    }

    .paragraph {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: $color-content-weak;
        display: block;
        margin-bottom: 20px;
    }

    .buttons {
        text-align: left;
        width: 100%;
        display: block;
        .btn {
            padding: $spacing-m $spacing-2xl;
            margin: 10px;
            width: auto;
            text-transform: capitalize;
            &:first-child {
                margin-left: 0;
            }
        }
    }

    .btn-outline-primary {
        background-color: white;
        color: $color-content;
        border: 2px solid $color-border;

        &:hover {
            background-color: $color-logo-primary;
            color: white;
            border: 2px solid $color-logo-primary;
        }
    }

    &.onboard-spend {
        padding: 40px;
    }

}