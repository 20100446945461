@import '@styles/variables.scss';

.alert {
    display: flex;
    align-items: flex-end;
    padding: $spacing-m !important;
    border-radius: $border-radius-default !important;
    border: 2px solid black;
    color: $color-content-weak;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    flex-shrink: 0;
    flex-basis: auto;
    flex-grow: 0;
    align-self: flex-start;

    & * {
        font-weight: 600;
    }

    svg {
        margin-right: 8px;
        margin-top: 2px;
    }

    .icon-container {
        &.top {
            align-self: flex-start;
        }

        &.bottom {
            align-self: flex-end;
        }

        &.middle {
            align-self: center;
        }
    }

    &.info {
        border: 2px solid $color-semantic-info-strong;

        svg path {
            fill: $color-semantic-info-strong;
        }
    }

    &.error {
        border: 2px solid $color-semantic-error;

        svg path {
            fill: $color-semantic-error;
        }
    }

    &.warning {
        border: 2px solid #C48A03;

        svg path {
            fill: #C48A03;
        }
    }

    &.success {
        border: 2px solid $color-semantic-positive;

        svg path {
            fill: $color-semantic-positive;
        }

        svg {
            width: 24px !important;
            height: 24px !important;
        }
    }

    &.done {
        border: 2px solid $color-semantic-info-strong;

        svg path {
            fill: $color-semantic-info-strong;
        }
    }
}