@import '@styles/variables.scss';

.receive-payment {
    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 32px;
        letter-spacing: 0.14px;
        color: $color-content;
        margin-bottom: 30px;
    }

    .sm-title {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.14px;
        color: #252930;
        margin-bottom: 0;
    }

    .box-content {
        margin-left: 19px;
        margin-top: 26px;
        position: relative;
        display: flex;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: $color-content;
        .icon {
            position: absolute;
            left: -39px;
            top: -9px;
            svg circle {
                fill: $color-semantic-info-strong;
                r: 12.5;
            }
        }
    }

    .box-content:first-of-type {
        > p:first-child {
            margin-left: 10px;
        }
        .instruction {
            margin-left: $spacing-s;
        }
    }

    .left-border-dashed {
        border-left: dashed 1px $color-semantic-info-strong;
        margin-top: -16px;
        margin-bottom: -26px;
    }

    span {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1925px;
        color: $color-content;

        &.enfasis-text {
            font-weight: 500;
        }
    }

    .orange-text {
        color: #e87754;

        span {
            color: #e87754;
        }
    }

    .card {
        padding: 23px 22px;
        border-radius: 8px;
        background-color: $color-background-page;
        border: none;

        &.invisible-card {
            background-color: transparent;
            margin-bottom: 10px;
        }

        .card-label {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.42px;
            color: $color-content;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }

        .card-value {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            padding: 0;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        .row > * {
            margin: 5px 0;
        }
    }

    .btn-add-more {
        background-color: transparent !important;
        color: $color-actionable-primary !important;
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        padding: 12px 0 !important;
        margin-right: 14px;

        &:disabled {
            cursor: wait;
        }

        text-decoration: none;
    }

    .confirmation-numbers label {
        color: $color-content;
        font-weight: 400;
        font-size: 14px;
        line-height: 15.4px;
    }

    .confirmation-buttons {
        margin: 20px 0;
    }

    .complete-booking {
        height: 56px;
        max-width: 486px;
    }

    .trigger-copy {
        margin-left: 2px;
        padding: 0px !important;
        background-color: transparent !important;
    }

    .btn-link:active, .btn-link:focus {
        background-color: transparent;
    }

    .barcode-container {
        display: flex;
        cursor: pointer;
        .barcode-fullscreen-icon {
            display: inline;
            margin-top: calc(142px / 4);
            margin-left: 6px;
        }
    }

    .alert-text {
        span {
            font-size: 14px !important;
        }
        color: $color-content;
        font-weight: 500;
        line-height: 24px;
    }

    span .alert-text {
        font-size: 14px !important;
    }

    .btn {
        width: fit-content;
        padding: $spacing-m $spacing-2xl;
    }
}
