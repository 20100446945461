#up-blocked {
  padding: 50px;
  .ad-blocker-image {
    display: block;
    margin: auto;
    padding: 0 10px;
  }

  .title, .details {
    text-align: center;
  }
}