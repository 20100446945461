@import '@styles/variables.scss';

.mini-estimated-results {
    display: inline-block;
    background: $color-background-page;
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 339px;
    max-width: 415px;
    padding: 30px 20px;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: auto;
    align-self: baseline;

    .header {
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: $color-content;
    }

    &.fr {
      padding: 30px 11px;
    }

    .dots-loading {
        display: block;
        margin-top: 120px;
        min-width: 250px;
    }

    p {
        margin-bottom: 8px;
      }
    .segment {
        margin-top: 20px;
    }
    
      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.42px;    
        color: $color-content;
        max-width: 234px;
      }
    
      .description {
        font-weight: normal;
        font-size: 16px;
        line-height: 14px;
        letter-spacing: 0.22px;
        color: $color-content-weak;
        max-width: 234px;
      }
    
      .big-price {
        color: $color-logo-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 32px;
        letter-spacing: 0.190125px;
      }
    
      .divider {
          border-bottom: 1px solid #D1D3D7;
          padding-top: 10px;
      }

    .price-container {
        position: relative;
        padding: 5px;
        display: flex;
        .price-description {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.1925px;
            color: $color-content;

            &.bold {
              font-weight: 500 !important;
            }
        }
        .price-value, .price-value-small {
          right: 0;
          position: absolute;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          text-align: right;
          letter-spacing: 0.1925px;
          color: $color-content;
        }

        .price-value-small {
          position: relative;
          text-align: unset;
        }
    }

    .note {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: $color-content-weak;
    }

}