@import"@styles/variables.scss";

#login-modal {
    .close {
        top: 15px;
        right: 38px;
        width: 32px;
        height: 32px;
        padding: 9px 8.858px 8.858px 9px;
    }

    .modal-footer {
        padding: 0;
        margin: 0;

        .form-check {
            margin: 0 !important;
        }
    }

    .modal-body-text {
        padding: 19px !important;
    }

    .login-modal-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        .language-selector.selected {
            color: #2EA59A;
        }
    }

    #divider {
        width: 100%;
        border-top: 1px solid #C0C1C4 !important;
    }

    .modal-body-text {
        max-width: unset;

        &.scrollable {
            max-height: 80vh;
            overflow-y: scroll;
        }
    }

    .modal-content {
        padding: 0 !important;
        max-height: 90vh;
        overflow-y: hidden;
    }

    .modal-dialog, .modal-content {
        width: 1064px !important;
    }

    .modal-header {
        padding: $spacing-2xl $spacing-l !important;
        background-color: $color-background-strong;
    }

    .modal-body {
        padding: 0;
        padding-top: 20px !important;
        overflow-y: scroll;

        p {
            color: $color-content-weak;
        }
    }

    .modal-title {
        margin-bottom: 0;
        color: $color-logo-primary;
    }

    .login-modal-footer {
        width: 100%;
        justify-content: space-between;
        padding: $spacing-l;
        margin-top: 0;

        button {
            width: auto;
            align-self: flex-end;
        }
        .input-group {
            width: auto;
            flex: 4;
            label {
                font-size: 16px;
                align-self: flex-start;
                margin-bottom: 0;
                margin-left: 6px;
            }
        }

        #accept-terms-id {
            border-radius: 0.25em;
        }
    }
}