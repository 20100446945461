@import '@styles/variables.scss';

#footer {
    color: $color-content;

    .copyright-title {
        font-size: 14px;
        line-height: 24px;
    }

    .footer-container {
        border-top: 1px solid $color-border;
        padding: $spacing-l 0;
    }

    svg {
        width: 20px;
        height: 20px;
        path {
            fill: black;
        }
    }

    .languages-wrapper {
        display: flex;
        align-items: center;
    }

    .language-selector {
        font-style: normal;
        background-color: transparent;
        font-size: 13px;
        color: $color-actionable-navigation-item;
        letter-spacing: -0.35px;
        cursor: pointer;
        margin-left: 5px;
        padding: 0;
        font-weight: 400;
        line-height: 24px;
        text-decoration-line: underline;

        &:after {
            margin-left: 5px;
            content: '|';
            color: #657183;
        }

        &:last-child::after {
            content: ' ';
        }

        &.selected {
            color: $color-actionable-primary;
        }
    }

    .links-container, .contact-links-container {
        display: flex;
        justify-content: flex-end;
        .footer-link {
            margin-right: 20px;
            display: flex;
            align-items: center;

            span button {
                color: $color-content !important;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                background: none;
                border: none;
            }
        }

        .footer-link:last-child() {
            margin-right: 0px;
        }

    }

    .contact-links-container {
        .footer-contact-link {
            justify-content: end;
            margin-right: 20px;
            font-size: 13px;
            line-height: 16px;

            span a {
                color: $color-content !important;
                text-align: center;
                font-size: 13px;
                font-style: normal;
                line-height: 24px;
                text-decoration: underline;
            }
        }
    }
}